<template>
    <div class="roomList">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>积分设置列表</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- -------表格----- -->
      <el-table
        :data="tableData"
        style="width: 100%"
        border
      >
        <el-table-column align="center" prop="signId" label="天数" >
        </el-table-column>
        <el-table-column align="center" prop="integral" label="积分" >
          <template slot-scope="{ row }">
            <el-input
              class="input-new-tag"
              v-if="row.inputVisible"
              v-model="row.integral"
              ref="saveTagInput"
              size="small"
              @input="onInput"
              @keyup.enter.native="handleInputConfirm(row)"
              @blur="handleInputConfirm(row)"
            >
            </el-input>
            <el-button v-else class="button-new-tag" @click="onChangeSort(row)">
              {{ row.integral }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="parmes.currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="parmes.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
  </template>
  <script>
  import {getSignIntegerList,modifySignInteger} from "@/api/signSetting";
  export default {
    name: "signIntegralSetting",
    data() {
      return {
        tableData:[],
        pagination:{},
        parmes:{
            currentPage:1,
            pageSize:5
        }
      };
    },
    created() {
      this.getIntegerList();
    },
    methods: {
      onInput(value) {
        var reg = /^[0-9]*[1-9][0-9]*$/;
        var reg2 = /^-[0-9]*[1-9][0-9]*$/;
        if (!reg.test(value) && !reg2.test(value)) {
          return this.$message({
            type: "warning",
            message: "请输入整数!",
          });
        }
      },
      async getIntegerList() {
        const { data } = await getSignIntegerList(this.parmes);
        data.list.map((item) => {
        item.inputVisible = false;
      });
        this.tableData = data.list;
        this.pagination = data.pagination;
      },
      onChangeSort(row) {
        row.inputVisible = true;
      },
      async handleInputConfirm(row) {
        const { data } = await modifySignInteger({
            signId: row.signId,
            integral: row.integral,
        });
        this.getIntegerList();
        row.inputVisible = false;
      },
      handleSizeChange(num) {
        this.parmes.pageSize = num;
        this.getIntegerList();
      },
      handleCurrentChange(num) {
        this.parmes.currentPage = num;
        this.getIntegerList();
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .roomList {
    .el-breadcrumb {
      height: 35px;
      border-bottom: 1px solid rgb(192, 191, 191);
    }
    .search {
      margin-top: 50px;
      .el-input {
        width: 200px;
      }
      span {
        margin: 0 20px;
      }
      .el-button {
        margin: 0 20px;
      }
    }
    .el-table {
      margin-top: 50px;
    }
    .el-pagination {
      margin-top: 50px;
      float: right;
    }
    .btn {
      margin-top: 50px;
    }
  }
  </style>
  